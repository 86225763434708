.account-lending-pool {
  margin-top: 1rem;
  padding: 0 0.5rem;

  .card {
    background: #0f1414;
  }

  .card-body {
    padding: 0 1rem;
  }

  .account-lending-pool-page-selector {
    display: flex;
    background: #1e2828;
    > * {
      border-top: 3px solid #1e2828;
      border-left: 1px solid #354747;
      padding: 10px 0px;
      width: 100%;
      text-align: center;
      font-size: 1.1875rem;
      cursor: pointer;
      font-weight: bold;
      color: #9ca3af;
    }
    > *:hover {
      background: #263333;
    }
    > .selected {
      border-top: 3px solid #e5e7eb;
      color: #e5e7eb;
      background: #0f1414 !important;
      border-bottom: 0;
      border-left: 0;
    }
  }

  .account-lending-pool-details {
    margin: 0;
    padding: 1rem 0;
  }
  .account-lending-pool-details > * {
    padding: 0 1.5rem;
  }

  .account-lending-pool-farming {
    padding: 2rem;
    .info {
      text-align: center;
      background: #d1e6ee82;
      padding: 1rem;
      border-radius: 8px;
      border: 2px solid #75abc0;
      color: #007baa;
    }
    .activate-tarot-reward {
      margin: 0 auto;
      width: 250px;
      margin-top: 2rem;
    }
  }

  .account-lending-pool-claim {
    .col-reward-rate {
      padding: 20px 30px;
      line-height: 45px;
    }
    .col-claim-reward {
      padding: 20px 30px;
      .interaction-button {
        height: 45px;
        width: 250px;
      }
    }
  }

  .claim-history {
    padding: 1rem;
  }

  .account-lending-pool-row {
    margin: 0;
    padding: 1rem 0;
    border-top: 1px solid #263333;

    .account-lending-pool-name-icon {
      align-items: center;
      height: 100%;
      .token-icon {
        padding: 0;
        max-width: 90px;
        text-align: center;
        img {
          width: 32px
        }
        &.icon-overlapped {
          img:first-of-type {
            z-index: 0;
            position: relative;
          }
          img:last-of-type {
            margin-left: -0.625rem;
            transform: translate3d(0px, 0px, 0px);
          }
        }
      }
      .token-name {
        font-size: 1.0675rem;
        padding: 0;
      }
    }

    .inline-account-token-info-container {
      display: flex;
      flex-flow: column;
      line-height: 1.2;
      .inline-account-token-info {
        align-items: center;
        width: 100%;
        padding: 0.5rem 0;
        flex: 1;
        .name {
          color: #e5e7eb;
          font-size: 94%;
        }
      }
    }

    .btn-table {
      padding:0;
      padding-left: 0.5rem;
      display: flex;
      flex-flow: column;
      .row {
        align-items: center;
        width: 100%;
        flex: 1;
        padding: 0.5rem 0;
        .btn {
          width: 130px;
          @media (min-width: 768px) and (max-width: 1023px) {
            font-size: smaller;
            width: 100px;
          }
          @media (max-width: 370px) {
            font-size: smaller;
            width: 100px;
          }
          height: 38px;
          line-height: 38px;
          border-radius: 0.375rem;
          background-color: #3c5151;
          color: #fff;
          border: none;
          padding: 0;
          box-shadow: none !important;
          &.leverage {
            background-color: #00793c;
          }
          &.obtain {
            background-color: #b30018;
          }
        }
      }
    }
  }

  .button-green {
    background-color: #00793c !important;
    height: 36px;
    color: #fff;
    padding: 0 1rem;
    border: 0;
    box-shadow: none !important;
  }
}